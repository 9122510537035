<template>
  <div class="projectSection">

    <div class="projectSectionLeft">
      <div class="projectSectionTitle monthBackground">Videos</div>
      <div class="imageCount desktopControls" v-if="project.videos.length > 1">
        <div class="left" @click="nextVideo('left')">&larr;</div>
        <div class="center">{{ pad(currentslide + 1, 2) }} / {{ pad(project.videos.length, 2)}}</div>
        <div class="right" @click="nextVideo('right')">&rarr;</div>
      </div>

      <div class="imageCaption desktopControls" v-if="currentCaption" v-html="currentCaption"></div>
    </div>

    <div class="projectSectionRight">
      <div class="slideshowVideo" ref="slides">
        <div class="slideshowImageInner" :style="{paddingBottom: pVim + '%'}">
          <div class="video" ref="vimeo" :class="{videoHide: videoHide}"></div>
          <div class="placeHolder"></div>
        </div>
      </div>
    </div>

    <div class="mobileControls">
      <div class="imageCount" v-if="project.videos.length > 1">
        <div class="left" @click="nextVideo('left')">&larr;</div>
        <div class="center">{{ pad(currentslide + 1, 2) }} / {{ pad(project.videos.length, 2)}}</div>
        <div class="right" @click="nextVideo('right')">&rarr;</div>
      </div>

      <div class="imageCaption" v-if="currentCaption" v-html="currentCaption"></div>
    </div>
  </div>
</template>

<script>
import VideoMixins from '@/components/A/Mixins/VideoMixins'

export default {
  props: ['project'],

  mixins: [VideoMixins],

  mounted () {
    this.initVideo()
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/slideshow.scss';

.single .imageCount {
  margin-top: $objectPadding;
}

.imageCaption.desktopControls {
  margin-top: $objectPadding;
}

.mobileControls .imageCount {
  margin-top: 0;
}
</style>
