<template>
  <div id="projects">
    <div class="project" v-for="(project, index) in $store.state.projects" :class="[project.videos && (project.type === performances || project.type === visual) ? 'visible' : 'hidden']" v-if="project.videos">

      <project-title :project="project" :ref="'titleHover' + index"></project-title>

      <auteurs :auteurs="project.auteurs" />

      <video-slide :key="project.id" :project="project"></video-slide>
    </div>
  </div>
</template>

<script>
import ProjectTitle from '@/components/A/Reusable/Title'
import VideoSlide from '@/components/Works/VideoSlide'
import Auteurs from '@/components/A/Reusable/Auteurs'

export default {
  components: {
    VideoSlide,
    ProjectTitle,
    Auteurs
  },

  props: ['type', 'performances', 'visual']
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

#projects {
  overflow: auto;
}

.hidden {
  visibility: hidden;
  display: none;
  padding-bottom: 0;
  height: 0;
}

.visible {
  visibility: visible;
  display: block;
  height: auto;
  padding-bottom: $projectSpacing;
}
</style>