<template>
  <div id="auteur" v-if="project">
    <div class="title">
      <div class="titleInner">About {{project.title}}</div>
    </div>

    <div class="projectSingle">
      <!-- Texts -->
      <texts :project="project"></texts>
    </div>
  </div>
</template>

<script>
import Texts from '@/components/Single/Texts'

export default {
  data () {
    return {
      project: false
    }
  },

  components: {
    Texts
  },

  created () {
    this.$axios.get('/type/' + this.$route.params.type).then(result => {
      this.project = result.data
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

.about .lanFilters {
  margin-top: $objectPadding;
}
</style>