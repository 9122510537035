<template>
  <div class="projectSection" v-if="project.texts">
    <div class="projectSectionLeft">
      <div class="projectSectionTitle monthBackground">{{$route.name === 'auteur' ? 'Biography' : 'Texts'}}</div>
      <div class="lanFilters">
        <div class="lanFilter" v-for="(text, index) in project.texts" :class="{active:text.lan === selected}" @click="changeText(text); selected = text.lan" >
          <div class="lanFilterInner">
            {{ text.lan | language }}
          </div>
        </div>
      </div>
    </div>

    <div class="projectSectionRight">
      <div class="projectBoxNo">
        <div class="langBox pitchBox" v-if="selectedText.pitch" :class="$store.state.monthComp">
          <div v-html="'' + selectedText.pitch + ''"></div>
        </div>

        <div class="langBox">
          <div v-html="selectedText.text"></div>
          <div class="eventAuteurs" v-if="selectedText.author || selectedText.translator">
            <template v-if="selectedText.author">{{selectedText.authorTrans}}: {{selectedText.author}}</template><br>
            <template v-if="selectedText.translator">{{selectedText.translatorTrans}}: {{selectedText.translator}}</template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selectedText: this.project.texts[0],
      selected: this.project.texts[0].lan
    }
  },

  props: ['project'],

  filters: {
    language (value) {
      if (value === 'English') return 'EN'
      if (value === 'French') return 'FR'
      if (value === 'German') return 'DE'
      if (value === 'Nederlands') return 'NL'
      if (value === 'Espanol') return 'ES'
      if (value === 'Italian') return 'IT'
      if (value === 'Greek') return 'GR'
    }
  },

  methods: {
    changeText (event) {
      this.selectedText = event
      this.$parent.projectTitle = this.selectedText.title ? this.selectedText.title : this.project.texts[0].title
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

ol {
  padding-left: 3.5rem;
}

.langBox {
  padding: $boxPadding;
  border: $border-square;
}

.pitchBox {
  margin-bottom: $objectPadding;
}

@media screen and (max-width: $breakPoint1) {
  .authors {
    margin-bottom: $objectPadding;
 }
}
</style>
