<template>
  <div class="singleProjectImages">
    <div class="leftSlide" @click="flickity.previous()" v-if="!$store.state.isTouch"></div><div class="rightSlide" @click="flickity.next()" v-if="!$store.state.isTouch"></div>

    <div class="slideshowImage" ref="slides">
      <template v-for="image in project.images">
        <div class="slideshowImageInner">
          <img :data-flickity-lazyload="$store.state.cmsFiles + image.src">
        </div>
      </template>
    </div>

    <div class="caption smallText">
      <div class="captionInner">
        <div class="slideCount">{{currentslide}} / {{ pad(project.images.length, 2)}}</div><div class="slideCaption" v-if="currentCaption" v-html="currentCaption"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Flickity from 'flickity'

export default {
  data () {
    return {
      flickity: false,
      currentCaption: this.project.images[0].caption,
      currentslide: '01'
    }
  },

  props: ['project'],

  methods: {
    pad (num, size) {
      var s = num + ''
      while (s.length < size) { s = '0' + s }
      return s
    },

    initSlider () {
      this.flickity = new Flickity(this.$refs.slides, this.$flickOp)

      let that = this
      this.flickity.on('change', function (index) {
        that.currentslide = that.pad(index + 1, 2)
        that.currentCaption = that.project.images[index].caption
      })
    }
  },

  created () {
    this.$nextTick(() => {
      this.initSlider()
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

.singleProjectImages {
  position: relative;
  margin-bottom: $titleSpacing;
}

.caption {
  position: sticky;
  bottom: 7rem;
  margin-right: $mainPadding;
  margin-left: $mainPadding;
  z-index: 2;
}

.captionInner {
  background-color: white;
  transform: translate(0,-100%);
  padding: 0.3rem 0.8rem 0.4rem;
  border: $border-square;
  position: absolute;
  top: -2rem;
  display: flex;
}

.slideCount {
  flex: 1;
  white-space: nowrap;
  font-variant-numeric: tabular-nums;
}

.slideCaption {
  margin-left: 1rem;
  display: inline;
}

.leftSlide,
.rightSlide {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  cursor: w-resize;
  z-index: 1;
}

.rightSlide {
  cursor: e-resize;
  left: 50%;
}

@media screen and (max-width: $breakPoint1) {
  .caption {
    position: relative;
    bottom: auto;
  }

  .singleProjectImages {
    margin-bottom: 0;
  }
} 
</style>
