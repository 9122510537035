<template>
  <div id="projects">
    <div class="project" v-if="performances === 'Performances' && !visual">
      <router-link :to="'/works/about/performances'" class="noUnderline">
        <div class="projectTitle">About Performances</div>
      </router-link>
  
      <texts-lan-works :texts="texts['Performances']" :link="performances"></texts-lan-works>
    </div>

    <div class="project" v-if="!performances && visual === 'Visual Works'">
      <router-link :to="'/works/about/performances'" class="noUnderline">
        <div class="projectTitle">About Visual Works</div>
      </router-link>
  
      <texts-lan-works :texts="texts['Visual Works']" :link="visual-works">></texts-lan-works>
    </div>

    <textslan class="project" v-for="(project, index) in $store.state.projects" v-if="project.texts && (project.type === performances || project.type === visual)" :key="project.id" :project="project" :index="index"></textslan>
  </div>
</template>

<script>
import Textslan from '@/components/Works/TextsLan'
import TextsLanWorks from '@/components/Works/TextsLanWorks'

export default {
  components: {
    Textslan,
    TextsLanWorks
  },

  props: ['performances', 'visual', 'texts']
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

.bookOuter {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 1rem;
}

.book {
  padding: $boxPadding;
  border: $border-square;
  margin-bottom: $projectSpacing;
  grid-column: 3/7;
}

.bookBottom p {
  margin-bottom: 0;
}
</style>