import Vimeo from '@vimeo/player'

export default {
  data () {
    return {
      nVim: false,
      pVim: this.project.videos[0].ratio,
      currentVideo: this.project.videos[0].vimeo,
      currentCaption: this.project.videos[0].caption,
      currentslide: 0,
      videoHide: false
    }
  },

  methods: {
    initVideo () {
      this.currentVideo = this.project.videos[0].vimeo

      var options = {
        id: this.currentVideo,
        width: '100%',
        height: '100%'
      }

      let element = this.$refs.vimeo
      this.nVim = new Vimeo(element, options)
      this.videoHide = true
    },

    nextVideo (value) {
      this.videoHide = false
      this.$nextTick(() => {
        this.currentslide = value === 'left' ? this.currentslide - 1 : this.currentslide + 1

        if (this.currentslide > this.project.videos.length - 1) {
          this.currentslide = 0
        }

        if (this.currentslide < 0) {
          this.currentslide = this.project.videos.length - 1
        }

        this.currentCaption = this.project.videos[this.currentslide].caption
        this.currentVideo = this.project.videos[this.currentslide].vimeo
        var subject = this.currentVideo
        var result = subject.match(/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i)
        this.nVim.loadVideo(result[1]).then(() => {
          this.$nextTick(() => {
            setTimeout(() => {
              this.videoHide = true
              this.pVim = this.project.videos[this.currentslide].ratio
            }, 300)
          })
        })
      })
    },

    pad (num, size) {
      var s = num + ''
      while (s.length < size) { s = '0' + s }
      return s
    }
  }
}
