<template>
  <div id="projects">
    <div class="project" v-for="(project, index) in $store.state.projects" v-if="project.auteurs && (project.type === performances || project.type === visual)">
      <project-title :project="project" :ref="'titleHover' + index"></project-title>

      <div class="projectContent">
        <div class="projectLeft">
          <project-thumb :project="project" :index="index" @highlight="highlightTitle"></project-thumb>
        </div>

        <div class="projectBoxNo">
          <template v-for="item in project.auteurs">
            <router-link :to="'/information/auteur/' + item.url" class="noUnderline">
              <div class="projectTitle auteurTitle" v-html="item.title"></div>
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectTitle from '@/components/A/Reusable/Title'
import ProjectThumb from '@/components/A/Reusable/Thumb'
import FilterMixins from '@/components/A/Mixins/FilterMixins'

export default {
  components: {
    ProjectTitle,
    ProjectThumb
  },

  props: ['performances', 'visual'],

  mixins: [FilterMixins]
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

@media screen and (max-width: $breakPoint1) {
  .projectBoxNo {
    grid-column: span 6;
  }
}
</style>