<template>
  <div class="dateLine">
    <div class="dateYear" :class="item.year ? '' : 'hideYear'">{{ item.year }}</div>
    <div class="dateRight">
      <div class="dateMonth">
        <template v-if="item.month == 'Jan' && item.start == '01'"></template>
        <div v-else class="month" v-html="item.month + '&nbsp;'"></div>
        <template v-if="item.month == 'Jan' && item.start == '01'"></template>
        <div class="flexday" v-else>{{ item.start }}{{ item.end ? '–' + item.end : '' }}</div>
      </div>

      <div class="dateTitle">
        <template v-if="item.website">
          <a :href="item.website" v-html="item.venue" target="_blank"></a>
        </template>
        <template v-else>
          <span v-html="item.venue"></span>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: ['item']
}
</script>

<style lang="scss">
@import '@/assets/scss/dates.scss';
</style>