<template>
  <div id="projects">
    <div class="project" v-for="(project, index) in $store.state.projects" :class="[project.images && (project.type === performances || project.type === visual) ? 'visible' : 'hidden']" v-if="project.images">
      <project-title :project="project" :ref="'titleHover' + index"></project-title>

      <auteurs :auteurs="project.auteurs" />

      <images-slide :project="project" :key="project.id" :type="type"></images-slide>
    </div>
  </div>
</template>

<script>
import ProjectTitle from '@/components/A/Reusable/Title'
import ImagesSlide from '@/components/Works/ImagesSlide'
import Auteurs from '@/components/A/Reusable/Auteurs'

export default {
  data () {
    return {
      change: false
    }
  },

  components: {
    ImagesSlide,
    ProjectTitle,
    Auteurs
  },

  props: ['type', 'performances', 'visual']
}
</script>

<style lang="scss">
.hidden {
  display: none;
}

.visible {
  display: block;
}
</style>