<template>
  <div class="projectContent">
    <div class="projectLeft">
      <div class="imageCount" v-if="project.videos.length > 1">
        <div class="left" @click="nextVideo('left')">&larr;</div>
        <div class="center">{{ pad(currentslide + 1, 2) }} / {{ pad(project.videos.length, 2)}}</div>
        <div class="right" @click="nextVideo('right')">&rarr;</div>
      </div>

      <div class="imageCaption" v-if="currentCaption" v-html="currentCaption"></div>
    </div>

    <div class="projectVideos" v-observe-visibility="visibilityChanged">
      <div class="slideshowVideo" ref="slides">
        <div :style="{paddingBottom: pVim + '%'}">
          <div class="video" ref="vimeo" :class="{videoHide: videoHide}"></div>
          <div class="placeHolder"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoMixins from '@/components/A/Mixins/VideoMixins'
import Vue from 'vue'
import VueObserveVisibility from 'vue-observe-visibility'
require('intersection-observer')
Vue.use(VueObserveVisibility)

export default {
  props: ['project', 'type'],

  mixins: [VideoMixins],

  methods: {
    visibilityChanged (isVisible, entry) {
      if (entry.isIntersecting) {
        this.initVideo()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/slideshow.scss';

.projectVideos {
  grid-column: 3/7;
  position: relative;
}

@media screen and (max-width: $breakPoint1) {
  .projectVideos {
    margin-bottom: $objectPadding;
    grid-row: 1;
    grid-column: span 6;
  }
}
</style>
