<template>
  <div class="credit">
    <div class="creditTitle">Auteurs:</div>
    <div class="creditText">
      <template v-for="(item, index) in credit">
        <router-link :to="'/information/auteur/' + item.url">{{item.title}}</router-link><template v-if="index !== credit.length-1">, </template>
      </template>   
    </div>
  </div>
</template>

<script>
export default {
  props: ['credit']
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

div.creditText:last-child p {
  margin: 0;
}

.credit {
  display: grid;
  column-gap: $objectPadding;
  grid-template-columns: repeat(10, 1fr);
  margin-bottom: $lineHeight;
}

.credit:last-child {
 margin-bottom: 0;
}

.creditTitle {
  grid-column: span 3;
}

.creditText {
  grid-column: span 7;
}

@media screen and (max-width: $breakPoint1) {
  .credit {
    grid-template-columns: repeat(1, 1fr);
  }

  .creditTitle,
  .creditText {
    grid-column: span 1;
  }
}
</style>