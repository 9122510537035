<template>
  <div id="content" v-if="$store.state.dates">
    <slideshow :images="$store.state.images"></slideshow>

    <!--
    <div class="title sticky">News</div>
    <div class="events news firstYear">
      <div class="calendarMonth" :class="$store.state.monthComp">
        <div class="news">
          <a href="http://www.theaterpreise.ch" target="_blank"><img src="@/assets/prix.jpg"></a>
        </div>

        <div class="newsText">
          <p>Swiss Grand Award for Theatre / Hans Reinhart Ring 2019 goes to Yan Duyvendak! The official ceremony will take place on May 24th at the Théâtre du Crochetan in Monthey.</p>
          <p>More on: <a href="http://www.theaterpreise.ch" target="_blank">www.theaterpreise.ch</a></p>
          <p class="smallText">Photo: © BAK / Geoffrey Cottenceau & Romain Rousset</p>
        </div>
      </div>
    </div>
-->

    <div class="title sticky">Tour Dates {{ firstYear }}</div>

    <!--     <div v-for="(year, name, index) in $store.state.dates"> -->
    <div v-for="(year, name, index) in $store.state.dates" :class="[index === 0 ? 'firstYear' : '']">
      <div class="title" v-if="index !== 0">Tour Dates {{ name }}</div>

      <div v-for="(months, name, index) in year" class="calendarMonth" :class="months.month">
        <div class="monthName" :class="months.month">
          <div class="monthNameInner">
            {{ months.month }}
          </div>
        </div>

        <div class="events">
          <month v-for="(performance, index) in orderDays(months.performances)" :performance="performance" :key="performance.id" :index="index" :month="months.month"></month>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiMixins from '@/components/A/Mixins/ApiMixins';
import lodash from 'lodash';
import Month from '@/components/A/Calendar/Month';
import Slideshow from '@/components/A/Calendar/Slideshow';

export default {
  data() {
    return {
      firstYear: false,
      images: false,
    };
  },

  components: {
    Slideshow,
    Month,
  },

  mixins: [ApiMixins],

  methods: {
    orderDays(value) {
      return lodash.orderBy(value, 'days', 'asc');
    },
  },

  created() {
    if (this.$store.state.dates) {
      this.firstYear = Object.keys(this.$store.state.dates)[0];
    } else {
      this.$axios.get('/calendar').then((result) => {
        this.$store.commit('dates', result.data.dates);
        this.$store.commit('images', result.data.images);
        this.firstYear = Object.keys(this.$store.state.dates)[0];

        if (!this.$store.state.projects) {
          this.$axios.get('/projects').then((result) => {
            this.sortWork(result.data.projects, result.data.texts);
          });
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

#content {
  position: relative;
}

.firstYear {
  margin-top: $titleSpacing;
}

.calendarMonth {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: $objectPadding;
  padding: $mainPadding;
}

.monthName {
  padding: $boxPadding;
  grid-column: span 1;
  text-align: center;
  border: $border-square;
}

.newsText {
  padding: $boxPadding;
  grid-column: span 2;
  border: $border-square;

  p:last-child {
    margin: 0;
  }
}

.monthNameInner {
  position: sticky;
  top: 1.5rem;
}

.events {
  grid-column: span 5;
}

.news {
  grid-column: span 4;
}

@media screen and (max-width: $breakPoint1) {
  .firstYear {
    margin-top: 0;
  }

  .calendarMonth {
    grid-row-gap: $objectPadding;
  }

  .monthName {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    grid-column: span 6;
    height: 8rem;
    margin-bottom: $mainPadding;
  }

  .monthNameInner {
    position: relative;
    top: auto;
  }

  .news,
  .newsText {
    grid-column: span 6;
  }

  .events {
    grid-column: span 6;
  }
}
</style>
