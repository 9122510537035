<template>
  <div class="projectSection downloads" v-if="project.downloads">
    <div class="projectSectionLeft">
      <div class="projectSectionTitle monthBackground">Downloads</div>
    </div>

    <div class="projectSectionRight">
      <div class="projectBox">
        <template v-for="download in project.downloads">

          <template v-if="download.type === 'image'">
            <p><a :href="$axios.defaults['baseURL'] + '/files/' + download.link + project.url">
              <font-awesome-icon :icon="'file-' + download.type" />&emsp;<span v-html="download.name" />
            </a></p>
          </template>
          <template v-else>
            <p><a target="_blank" :href="$store.state.cmsFiles + download.link">
              <font-awesome-icon :icon="'file-' + download.type" />&emsp;<span v-html="download.name" />
            </a></p>
          </template>

        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFilePdf, faFileVideo, faFileImage, faFileArchive } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faFilePdf, faFileVideo, faFileImage, faFileArchive)
Vue.component('font-awesome-icon', FontAwesomeIcon)

export default {
  props: ['project']
}
</script>

<style lang="css">
.downloads a {
  text-decoration: none;
}

.downloads p {
  margin-bottom: 1rem;
}

.downloads p:last-child {
  margin: 0;
}
</style>
