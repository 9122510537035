<template>
  <div class="calendarProjectImages">

    <div class="slideshowImage" ref="slides">
      <template v-for="image in randomList">
        <div class="slideshowImageInner">
          <img :data-flickity-lazyload="$store.state.cmsFiles + image.src">
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import Flickity from 'flickity'

export default {
  data () {
    return {
      flickity: false,
      currentCaption: this.images[0].caption,
      currentslide: '01'
    }
  },

  props: ['images'],

  computed: {
    randomList () {
      return this.images.sort(function () { return 0.5 - Math.random() })
    }
  },

  methods: {
    pad (num, size) {
      var s = num + ''
      while (s.length < size) { s = '0' + s }
      return s
    },

    initSlider () {
      this.flickity = new Flickity(this.$refs.slides, {
        draggable: false,
        wrapAround: true,
        autoPlay: 5000,
        pauseAutoPlayOnHover: false,
        accessibility: false,
        setGallerySize: false,
        lazyLoad: 2,
        selectedAttraction: 0.05,
        friction: 0.5,
        prevNextButtons: false,
        pageDots: false,
        initialIndex: 0
      })

      let that = this
      this.flickity.on('change', function (index) {
        that.currentslide = that.pad(index + 1, 2)
        that.currentCaption = that.images[index].caption
      })
    }
  },

  created () {
    this.$nextTick(() => {
      this.initSlider()
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';
.calendarProjectImages {
  pointer-events: none;
  position: relative;
  margin-bottom: $titleSpacing;
}

@media screen and (max-width: $breakPoint1) {
  .calendarProjectImages {
    margin-bottom: 0;
  }
} 
</style>
