<template>
  <div>
    <project-title :project="project" :ref="'titleHover' + index"></project-title>

    <auteurs :auteurs="project.auteurs" />

    <div class="projectContent">
      <div class="projectLeft">

        <div class="lanFilters">
          <div class="lanFilter" v-for="(text, index) in project.texts" :class="{active:text.lan === selected}" @click="changeText(text); selected = text.lan" >
            <div class="lanFilterInner">
              {{ text.lan | language }}
            </div>
          </div>
        </div>

        <project-thumb :project="project" :index="index" @highlight="highlightTitle"></project-thumb>

      </div>

      <div class="projectBoxNo">
        <div class="langBox pitchBox" v-if="selectedText.pitch" :class="$store.state.monthComp">
          <div v-html="'' + selectedText.pitch + ''"></div>
        </div>

        <div class="langBox">
          <div v-html="selectedText.text"></div>
          <div class="eventAuteurs" v-if="selectedText.author || selectedText.translator">
            <template v-if="selectedText.author">{{selectedText.authorTrans}}: {{selectedText.author}}</template><br>
            <template v-if="selectedText.translator">{{selectedText.translatorTrans}}: {{selectedText.translator}}</template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectTitle from '@/components/A/Reusable/Title'
import ProjectThumb from '@/components/A/Reusable/Thumb'
import FilterMixins from '@/components/A/Mixins/FilterMixins'
import Auteurs from '@/components/A/Reusable/Auteurs'

export default {
  data () {
    return {
      selectedText: this.project.texts[0],
      selected: this.project.texts[0].lan
    }
  },

  props: ['project', 'index'],

  components: {
    ProjectTitle,
    ProjectThumb,
    Auteurs
  },

  mixins: [FilterMixins],

  filters: {
    language (value) {
      if (value === 'English') return 'EN'
      if (value === 'French') return 'FR'
      if (value === 'German') return 'DE'
      if (value === 'Nederlands') return 'NL'
      if (value === 'Espanol') return 'ES'
      if (value === 'Italian') return 'IT'
      if (value === 'Greek') return 'GR'
    }
  },

  methods: {
    changeText (event) {
      this.selectedText = event
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

.authors {
  margin-bottom: $objectPadding;
}

/*
div.langBox p:last-child {
  margin: 0;
}
*/

.langBox {
  padding: $boxPadding;
  border: $border-square;
}

.pitchBox {
  margin-bottom: $objectPadding;
}
</style>
