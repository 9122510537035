<template>
  <div id="projects">
    <div class="project" v-for="(project, index) in $store.state.projects" v-if="project.dates && (project.type === performances || project.type === visual)">
      <project-title :project="project" :ref="'titleHover' + index"></project-title>
      
      <auteurs :auteurs="project.auteurs" />

      <div class="projectContent">
        <div class="projectLeft">
          <project-thumb :project="project" :index="index" @highlight="highlightTitle"></project-thumb>
        </div>

        <div class="projectRight">
          <div class="projectBoxNo" v-if="project.futureDates">
            <div class="projectSectionTitle dateSectionTitle">Upcoming Tour Dates</div>
          </div>
  
          <div class="projectBoxNo futureBox" v-if="project.futureDates">
            <dates-future :project="project"></dates-future>
          </div>

          <div class="projectBoxNo" v-if="project.dates.length">
            <div class="projectSectionTitle dateSectionTitle">Previous Tour Dates</div>
          </div>
  
          <div class="projectBox" v-if="project.dates.length">
            <project-date v-for="(item, index) in project.dates" :key="index" :item="item"></project-date>
          </div>
        </div>
      </div>
    </div>

    <div class="noDatesOuter" v-if="filtered.length < 1">
      <div class="noDates">
        <div class="bookTop">No upcoming dates, or previous dates to show.</div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectTitle from '@/components/A/Reusable/Title'
import ProjectThumb from '@/components/A/Reusable/Thumb'
import Auteurs from '@/components/A/Reusable/Auteurs'
import DatesFuture from '@/components/A/Reusable/DatesFuture'
import ProjectDate from '@/components/A/Reusable/Date'
import FilterMixins from '@/components/A/Mixins/FilterMixins'

export default {
  components: {
    ProjectTitle,
    Auteurs,
    ProjectThumb,
    DatesFuture,
    ProjectDate
  },

  props: ['performances', 'visual'],

  computed: {
    filtered () {
      return this.$store.state.projects.filter(item => {
        return (item.type === this.performances || item.type === this.visual) && item.dates
      })
    }
  },

  mixins: [FilterMixins]
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

.noDatesOuter {
  position: relative;
}

.futureBox {
  margin-bottom: $objectPadding;
}

.noDates {
  position: relative;
  height: 100%;
  padding: $boxPadding;
  border: $border-square;
}

.noDates p:last-child {
  margin-bottom: 0;
}

.projectRight {
  grid-column: 3/7;
}

@media screen and (max-width: $breakPoint1) {
  .projectRight {
    grid-column: span 6;
  }
}
</style>