export default {
  methods: {
    sortWork (projects, texts) {
      let projectsSorted1 = []
      let projectsSorted2 = []
      let projectsSorted3 = []
      let projectsSorted4 = []

      projects.forEach(function (element) {
        if ((element.status_id === 1 || element.status_id === 3) && element.type === 'Performances') {
          projectsSorted1.push(element)
        }

        if ((element.status_id === 1 || element.status_id === 3) && element.type === 'Visual Works') {
          projectsSorted2.push(element)
        }

        if (element.status_id === 2 && element.type === 'Performances') {
          projectsSorted3.push(element)
        }

        if (element.status_id === 2 && element.type === 'Visual Works') {
          projectsSorted4.push(element)
        }
      })

      this.$store.commit('texts', texts)
      this.$store.commit('projects', {
        'all': projectsSorted1.concat(projectsSorted2, projectsSorted3, projectsSorted4),
        'current': projectsSorted1.concat(projectsSorted2),
        'archive': projectsSorted3.concat(projectsSorted4)
      })
    }
  }
}
