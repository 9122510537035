<template>
  <div class="projectSection" v-if="project.dates">
    <div class="projectSectionLeft">
      <div class="projectSectionTitle monthBackground dateSectionTitle">Previous Tour Dates</div>
    </div>

    <div class="projectSectionRight">
      <div class="projectBox">
        <project-date v-for="item in project.dates" :key="item.id" :item="item" class="dateLine"></project-date>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectDate from '@/components/A/Reusable/Date'

export default {
  components: {
    ProjectDate
  },

  props: ['project']
}
</script>