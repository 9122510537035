import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const date = new Date()
const month = months[date.getMonth()]
let monthComp

switch (month) {
  case 'January':
    monthComp = 'July'
    break
  case 'February':
    monthComp = 'August'
    break
  case 'March':
    monthComp = 'September'
    break
  case 'April':
    monthComp = 'October'
    break
  case 'May':
    monthComp = 'November'
    break
  case 'June':
    monthComp = 'December'
    break
  case 'July':
    monthComp = 'January'
    break
  case 'August':
    monthComp = 'February'
    break
  case 'September':
    monthComp = 'March'
    break
  case 'October':
    monthComp = 'April'
    break
  case 'November':
    monthComp = 'May'
    break
  case 'December':
    monthComp = 'June'
}

const state = {
  information: false,
  dates: false,
  texts: false,
  images: false,
  projects: false,
  current: Object,
  isTouch: false,
  archive: Object,
  month: month,
  monthComp: monthComp,
  cmsFiles: 'https://duyvendak.com/cms/site/assets/files/'
}

const mutations = {
  projects (state, projects) {
    state.projects = projects.all
    state.current = projects.current
    state.archive = projects.archive
  },

  information (state, information) {
    state.information = information
  },

  texts (state, texts) {
    state.texts = texts
  },

  dates (state, dates) {
    state.dates = dates
  },

  images (state, images) {
    state.images = images
  },

  isTouch (state, isTouch) {
    state.isTouch = isTouch
  }
}

const actions = {
  isTouch (context, isTouch) {
    context.commit('isTouch', isTouch)
  },

  dates (context, dates) {
    context.commit('dates', dates)
  },

  texts (context, texts) {
    context.commit('texts', texts)
  },

  images (context, images) {
    context.commit('images', images)
  },

  information (context, information) {
    context.commit('information', information)
  },

  projects (context, projects) {
    context.commit('projects', projects)
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions
})
