<template>
  <div id="project" v-if="project">
    <slideshow :project="project"></slideshow>

    <div class="title sticky" v-html="projectTitle"></div>

    <div class="projectSingle">
      <auteurs :project="project"></auteurs>

      <div class="projectSection">
        <div class="projectSectionLeft">
          <div class="projectSectionTitle monthBackground">{{project.status}}</div>
        </div>
    
        <div class="projectSectionRight">
          <div class="projectBox availabiltiy" v-html="project.availability"></div>
        </div>
      </div>

      <texts :project="project"></texts>

      <div class="projectSection" v-if="project.futureDates">
        <div class="projectSectionLeft">
          <div class="projectSectionTitle monthBackground dateSectionTitle">Upcoming Tour Dates</div>
        </div>
    
        <div class="projectSectionRight">
          <dates-future :project="project"></dates-future>
        </div>
      </div>

      <dates :project="project" v-if="project.dates && project.dates.length > 0"></dates>

      <premiere :project="project"></premiere>

      <project-video :project="project" v-if="project.videos"></project-video>

      <credits :project="project"></credits>

      <downloads :project="project"></downloads>

    </div>
  </div>
</template>

<script>
import ApiMixins from '@/components/A/Mixins/ApiMixins'
import Auteurs from '@/components/Single/Auteurs'
import Credits from '@/components/Single/Credits'
import Dates from '@/components/Single/Dates'
import DatesFuture from '@/components/A/Reusable/DatesFuture'
import Downloads from '@/components/Single/Downloads'
import Premiere from '@/components/Single/Premiere'
import Slideshow from '@/components/Single/Slideshow'
import ProjectVideo from '@/components/Single/Videoshow'
import Texts from '@/components/Single/Texts'

export default {
  data () {
    return {
      project: false,
      projectTitle: false
    }
  },

  components: {
    Auteurs,
    Credits,
    Dates,
    DatesFuture,
    Downloads,
    Premiere,
    Slideshow,
    Texts,
    ProjectVideo
  },

  mixins: [ApiMixins],

  created () {
    if (this.$store.state.projects) {
      this.project = this.$store.state.projects.filter(item => {
        return item.url === this.$route.params.title
      })[0]

      this.projectTitle = this.project.title
    } else {
      this.$axios.get('/projects/' + this.$route.params.title).then(result => {
        this.project = result.data
        this.projectTitle = this.project.title

        this.$axios.get('/projects').then(result => {
          this.sortWork(result.data.projects, result.data.texts)
        })
      })
    }

    if (!this.$store.state.information) {
      this.$axios.get('/information').then(result => {
        this.$store.commit('information', result.data.contacts)
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

.availabiltiy p:last-child {
  margin-bottom: 0;
}

#project {
  position: relative;
  width: 100%;
}

.single .lanFilters {
  margin-top: $objectPadding;
}

@media screen and (max-width: $breakPoint1) {
  #project {
    position: relative;
    top: 0;
    width: 100%;
  }
}
</style>