// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import axios from "axios";
import { cacheAdapterEnhancer } from "axios-extensions";
import App from "@/App";
import router from "@/router";
import store from "@/store";
import VueAnalytics from "vue-analytics";

Vue.config.productionTip = false;

// axios api default url
// const config = require("../config");
Vue.prototype.$axios = axios.create({
  baseURL: "https://duyvendak.com/cms/api",
  adapter: cacheAdapterEnhancer(axios.defaults.adapter)
});

Vue.use(VueAnalytics, {
  id: "UA-123681878-1",
  router
});

Vue.prototype.$flickOp = {
  draggable: true,
  wrapAround: true,
  autoPlay: false,
  pauseAutoPlayOnHover: false,
  accessibility: false,
  setGallerySize: false,
  lazyLoad: 2,
  selectedAttraction: 0.05,
  friction: 0.5,
  prevNextButtons: false,
  pageDots: false,
  initialIndex: 0
};

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  render: h => h(App)
});
