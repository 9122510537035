<template>
  <div class="projectContent">
    <div class="projectLeft">
      <div class="imageCount">
        <div class="left" @click="flickity.previous()">&larr;</div>
        <div class="center">{{currentslide}} / {{ pad(project.images.length, 2)}}</div>
        <div class="right" @click="flickity.next()">&rarr;</div>
      </div>

      <div class="imageCaption" v-if="currentCaption" v-html="currentCaption"></div>
    </div>

    <div class="projectImages">
      <div class="leftSlide" @click="flickity.previous()" v-if="!$store.state.isTouch"></div><div class="rightSlide" @click="flickity.next()" v-if="!$store.state.isTouch"></div>

      <div class="slideshowImage" ref="slides">
        <template v-for="image in project.images">
          <div class="slideshowImageInner">
            <img :src="$store.state.cmsFiles + image.src">
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Flickity from 'flickity'

export default {
  data () {
    return {
      flickity: false,
      currentCaption: this.project.images[0].caption,
      currentslide: '01'
    }
  },

  props: ['project', 'type'],

  watch: {
    type () {
      let that = this
      this.flickity.destroy()
      this.$nextTick(() => {
        that.initSlider()
      })
    }
  },

  methods: {
    pad (num, size) {
      var s = num + ''
      while (s.length < size) { s = '0' + s }
      return s
    },

    initSlider () {
      this.flickity = new Flickity(this.$refs.slides, this.$flickOp)

      let that = this
      this.flickity.on('change', function (index) {
        that.currentslide = that.pad(index + 1, 2)
        that.currentCaption = that.project.images[index].caption
      })

      this.currentCaption = that.project.images[0].caption

      this.$nextTick(() => {
        this.flickity.reposition()
        this.currentslide = '01'
      })
    }
  },

  mounted () {
    this.initSlider()
  },

  destroyed () {
    this.currentslide = '01'
    this.flickity.destroy()
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/slideshow.scss';
</style>
