<template>
  <div class="event">
    <project-title :project="performance" :ref="'titleHover' + index"></project-title>
    
    <auteurs :auteurs="performance.auteurs" />

    <div class="eventContent">
      <div class="days">
        <div class="day" v-for="day in performance.days" :class="[performance.days.length === 1 ? 'singleDay' : '']">{{ day }}</div>
      </div>

      <div class="eventImage">
        <project-thumb :project="performance" :index="index" @highlight="highlightTitle"></project-thumb>
      </div>


      <div class="eventInfo">
        <template v-if="performance.venue.event">
          <div class="eventName"><a :href="performance.venue.event" v-html="performance.venue.name" target="_blank"></a></div>
        </template>
        <template v-else>
          <div class="eventName" v-html="performance.venue.name"></div>
        </template>
        <div class="eventAddress" v-html="performance.venue.address" v-if="performance.venue.address"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectTitle from '@/components/A/Reusable/Title'
import ProjectThumb from '@/components/A/Reusable/Thumb'
import FilterMixins from '@/components/A/Mixins/FilterMixins'
import Auteurs from '@/components/A/Reusable/Auteurs'

export default {
  data () {
    return {
      thumbnail: this.$store.state.cmsFiles + this.performance.thumbnail.src
    }
  },

  components: {
    ProjectTitle,
    ProjectThumb,
    Auteurs
  },

  props: ['performance', 'index', 'month'],

  mixins: [FilterMixins]
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

.event {
  margin-bottom: $projectSpacing;
}

.eventAddress {
  margin-top: $lineHeight;
}

div.event:last-child {
  margin-bottom: 0;
}

.eventContent {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: $objectPadding;
}

.eventImage {
  grid-column: 2/4;
}

.eventInfo {  
  padding: $boxPadding;
  border: $border-square;
  grid-column: 4/6;
  display: flex;
  flex-direction: column;
}

.eventName {
  flex: 1;
}

.days {
  grid-column: span 1;
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-auto-rows: min-content;
  grid-column-gap: $objectPadding;
  grid-row-gap: $objectPadding;
  font-variant-numeric: tabular-nums;
}

.day {
  text-align: center;
  padding: $boxPadding;
  border: $border-square;
}

.singleDay {
  grid-column: span 2;
}

@media screen and (max-width: $breakPoint1) {
  .eventContent {
    grid-template-columns: repeat(2, 1fr);
  }
      
  .eventInfo {
    grid-column: span 2;
    margin-top: $objectPadding;
  }

  .days {
    grid-column: span 1;
  }

  .eventImage {
    grid-column: span 1;
  }

  .eventLink {
    margin-top: $lineHeight;
  }
}
</style>
