<template>
  <div>
    <div class="projectContent">
      <div class="projectLeft">
        <div class="lanFilters">
          <div class="lanFilter" v-for="(text, index) in texts" :class="{active:text.lan === selected}" @click="changeText(text); selected = text.lan" >
            <div class="lanFilterInner">
              {{ text.lan | language }}
            </div>
          </div>
        </div>
      </div>

      <div class="projectBoxNo">
        <div class="langBox">
          <div v-html="$options.filters.truncate(selectedText.text, 500)"></div>
          <router-link :to="'/works/about/' + link">          
            <p class="read">Read more...</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selectedText: this.texts[0],
      selected: this.texts[0].lan
    }
  },

  props: ['texts', 'link'],

  filters: {
    language (value) {
      if (value === 'English') return 'EN'
      if (value === 'French') return 'FR'
      if (value === 'German') return 'DE'
      if (value === 'Nederlands') return 'NL'
      if (value === 'Espanol') return 'ES'
      if (value === 'Italian') return 'IT'
      if (value === 'Greek') return 'GR'
    }
  },

  methods: {
    changeText (event) {
      this.selectedText = event
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

.authors {
  margin-bottom: $objectPadding;
}

div.projectBoxNo p.read {
  margin-top: $objectPadding;
}

.langBox {
  padding: $boxPadding;
  border: $border-square;
}

.pitchBox {
  margin-bottom: $objectPadding;
}
</style>
