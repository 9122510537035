import Vue from 'vue'
import Router from 'vue-router'
import Calendar from '@/components/Calendar'
import Works from '@/components/Works'
import Single from '@/components/Single'
import Information from '@/components/Information'
import About from '@/components/About'
import Auteur from '@/components/Auteur'

// Filter views
import All from '@/components/Works/All'
import Auteurs from '@/components/Works/Auteurs'
import Credits from '@/components/Works/Credits'
import Dates from '@/components/Works/Dates'
import Downloads from '@/components/Works/Downloads'
import Images from '@/components/Works/Images'
import Texts from '@/components/Works/Texts'
import Videos from '@/components/Works/Videos'

Vue.use(Router)

const workChildren = [
  {
    path: '',
    component: All,
    meta: {
      scroll: 'works'
    }
  },
  {
    path: 'auteurs',
    component: Auteurs,
    meta: {
      scroll: 'works',
      title: 'Auteurs',
      name: 'auteurs'
    }
  },
  {
    path: 'credits',
    component: Credits,
    meta: {
      scroll: 'works',
      title: 'Credits',
      name: 'credits'
    }
  },
  {
    path: 'dates',
    component: Dates,
    meta: {
      scroll: 'works',
      title: 'Dates',
      name: 'dates'
    }
  },
  {
    path: 'downloads',
    component: Downloads,
    meta: {
      scroll: 'works',
      title: 'Downloads',
      name: 'downloads'
    }
  },
  {
    path: 'images',
    component: Images,
    meta: {
      scroll: 'works',
      title: 'Images',
      name: 'images'
    }
  },
  {
    path: 'texts',
    component: Texts,
    props: { scroll: 'works' },
    meta: {
      scroll: 'works',
      title: 'Texts',
      name: 'texts'
    }
  },
  {
    path: 'videos',
    component: Videos,
    props: { scroll: 'works' },
    meta: {
      scroll: 'works',
      title: 'Videos',
      name: 'videos'
    }
  }
]

export default new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (from.meta.scroll === 'works' && to.meta.scroll === 'works') {
      // return false
    } else if (from.name === 'info') {
      return { x: 0, y: 0 }
    } else if (to.name !== 'works' && from.name !== 'single') {
      return { x: 0, y: 0 }
    } else if (to.name === 'single' || to.name === 'info') {
      return { x: 0, y: 0 }
    }
  },

  routes: [
    {
      path: '/works',
      redirect: '/works/all'
    },
    {
      path: '/',
      component: Calendar,
      name: 'calendar'
    },
    {
      path: '/works/single/:title',
      component: Single,
      name: 'single'
    },
    {
      path: '/works/all',
      component: Works,
      props: { type: 'all' },
      children: workChildren
    },
    {
      path: '/works/visual',
      component: Works,
      props: { type: 'visual' },
      children: workChildren
    },
    {
      path: '/works/performances',
      component: Works,
      props: { type: 'performances' },
      children: workChildren
    },
    {
      path: '/works/about/:type',
      component: About,
      name: 'about works'
    },
    {
      path: '/information',
      component: Information,
      name: 'info'
    },
    {
      path: '/information/auteur/:title',
      component: Auteur,
      name: 'auteur'
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})
