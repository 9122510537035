<template>
  <div id="content">
    <filters :type="type"></filters>
    <div class="title">{{filterTitle}}</div>

    <router-view :performances="performances" :visual="visual" :texts="$store.state.texts" :type="type" v-if="$store.state.projects"></router-view>
  </div>
</template>

<script>
import ApiMixins from '@/components/A/Mixins/ApiMixins'
import Filters from '@/components/Filters'

export default {
  data () {
    return {
      performances: this.type === 'performances' || this.type === 'all' ? 'Performances' : '',
      visual: this.type === 'visual' || this.type === 'all' ? 'Visual Works' : '',
      filterTitle: false,
      filType: false
    }
  },

  props: ['type'],

  components: {
    Filters
  },

  watch: {
    '$route' (to, from) {
      this.setTitle()
    },

    type (value) {
      this.checkType(value)
    }
  },

  methods: {
    checkType (value) {
      this.performances = value === 'performances' || value === 'all' ? 'Performances' : ''
      this.visual = value === 'visual' || value === 'all' ? 'Visual Works' : ''
    },

    setTitle () {
      let filType
      let filView
      if (this.type === 'all') filType = 'Performances and Visual Works'
      if (this.type === 'performances') filType = 'Performances'
      if (this.type === 'visual') filType = 'Visual Works'
      if (this.$route.meta.title) {
        filView = ': ' + this.$route.meta.title
      } else {
        filView = ''
      }
      this.filType = filType
      this.filterTitle = filType + filView
    }
  },

  mixins: [ApiMixins],

  created () {
    this.setTitle()

    if (!this.$store.state.projects) {
      this.$axios.get('/projects').then(result => {
        this.sortWork(result.data.projects, result.data.texts)
      })
    }

    if (!this.$store.state.information) {
      this.$axios.get('/information').then(result => {
        this.$store.commit('information', result.data.contacts)
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

#projects {
  padding: 0 $mainPadding $mainPadding;
}

.projects {
  padding: 0 $mainPadding $mainPadding;
}

.projects:first-child {
  padding-bottom: 0;
}

.project {
  padding-bottom: $projectSpacing;
}

.project:last-child {
  padding-bottom: 0;
}

.projectContent {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: $objectPadding;
}

@media screen and (max-width: $breakPoint1) {
  #projects {
    .projectThumb {
      display: none;
    }
  
    .thumbAll.projectThumb {
      display: block;
    }
  
    .projectBoxNo,
    .projectBox {
      grid-column: span 6;
    }
  }
}
</style>
