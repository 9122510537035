<template>

  <div class="projectSection" v-if="project.auteurs">
    <div class="projectSectionLeft">
      <div class="projectSectionTitle monthBackground">Auteurs</div>
    </div>

    <div class="projectSectionRight">
      <template v-for="item in project.auteurs">
        <router-link :to="'/information/auteur/' + item.url" class="noUnderline">
          <div class="projectTitle auteurTitle" v-html="item.title"></div>
        </router-link>
      </template>
    </div>
  </div>

</template>

<script>
export default {
  props: ['project']
}
</script>