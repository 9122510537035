<template>
  <div id="filters">
    <div class="filterBar" :class="[$store.state.month, {'mobFilters' : filtersVisible}]">
      <router-link event="" @click.native="removeType('/works/performances/' + workName)" :to="'/works/performances/' + workName" tag="div" class="filter">Performances</router-link>
      <router-link event="" @click.native="removeType('/works/visual/' + workName)" :to="'/works/visual/' + workName" tag="div" class="filter">Visual Works</router-link>
    </div>

    <div class="filterBar allFilters" :class="[$store.state.monthComp, {'mobFilters' : filtersVisible}]">
      <router-link event="" @click.native="removeFilter('/works/' + type + '/videos')" :to="'/works/' + type + '/videos'" tag="div" class="filter">Videos</router-link>
      <router-link event="" @click.native="removeFilter('/works/' + type + '/texts')" :to="'/works/' + type + '/texts'" tag="div" class="filter">Texts</router-link>
      <router-link event="" @click.native="removeFilter('/works/' + type + '/images')" :to="'/works/' + type + '/images'" tag="div" class="filter">Images</router-link>
      <div class="filter break"></div>
      <router-link event="" @click.native="removeFilter('/works/' + type + '/auteurs')" :to="'/works/' + type + '/auteurs'" tag="div" class="filter">Auteurs</router-link>
      <router-link event="" @click.native="removeFilter('/works/' + type + '/downloads')" :to="'/works/' + type + '/downloads'" tag="div" class="filter">Downloads</router-link>
      <div class="filter break"></div>
      <router-link event="" @click.native="removeFilter('/works/' + type + '/dates')" :to="'/works/' + type + '/dates'" tag="div" class="filter">Dates</router-link>
      <router-link event="" @click.native="removeFilter('/works/' + type + '/credits')" :to="'/works/' + type + '/credits'" tag="div" class="filter">Credits</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      filtersVisible: false,
      workName: '',
      prevS: this.$route.path
    }
  },

  watch: {
    '$route' (to, from) {
      this.updateFilter()
    }
  },

  props: ['type'],

  methods: {
    updateFilter () {
      this.workName = this.$route.meta.name ? this.$route.meta.name : ''
    },

    removeFilter (e) {
      if (this.$route.path === e) {
        this.$router.push({path: '/works/' + this.type})
      } else {
        this.$router.push({path: e})
      }
    },

    removeType (e) {
      if (this.$route.path === e) {
        this.$router.push({path: '/works/all/' + this.workName})
      } else {
        this.$router.push({path: e})
      }
    }
  },

  created () {
    this.updateFilter()
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

#filters a {
  text-decoration: none;
}

.filterBar {
  width: 100%;
  display: flex;
  padding: $mainPadding $objectPadding $mainPadding $mainPadding;
}

.showFilters {
  display: none;
  padding: 0 $mainPadding;
}

.filter {
  user-select: none;
  position: relative;
  cursor: pointer;
  flex-grow: 1;
  margin-right: $objectPadding;
  border: $border-square;
  border-radius: 10rem;
  padding: $boxPaddingFilters;
  display: flex;
  justify-content: center;
  transition: flex 0.5s ease, flex-grow 0.5s ease, justify-content 0.5s ease, background-color 0.5s, color 0.5s; 
}

.filter.break {
  display: none
}

.filter.router-link-active {
  flex-grow: 10;
  //background-color: white;
}

.filter::before {
  content: "\00d7";
  position: absolute;
  top: 0.1rem;
  right: 0.8rem;
  opacity: 0;
  transition: opacity 0.2s ease 0.1s;
}

.filter.router-link-active::before {
  opacity: 1;
}

@media screen and (max-width: $breakPoint1) {
  .activeFilters {
    margin-bottom: $mainPadding;
  }

  .activeFilters .filter {
    background-color: black;
    color: white;
  }

  .filterBar {
    flex-wrap: wrap;
  }

  .showFilters {
    display: block;
  }

  .filterBar.allFilters {
    padding-top: 1.5rem;
  }

  .filterBar.allFilters .filter.break {
    display: block;
    border: none;
    width: 100%;
    margin: 0;
    flex: auto;
  }

  .filterBar.allFilters .filter {
    flex: 1;
    margin-top: 0.5rem;
  }

  .filter::before {
    right: 0.8rem;
  }

  .filterBar.allFilters .filter.router-link-active {
    flex: 4;
  }

  .filter:last-child,
  .filter:nth-child(3),
  .filter:nth-child(6) {
    margin-right: 0;
  }

  .mobFilters {
    display: none;
  }
}
</style>
