<template>
  <router-link :to="'/works/single/' + project.url" class="noUnderline" :class="{hovered: hovered}">
    <div class="projectTitle" v-html="project.title"></div>
  </router-link>
</template>

<script>
export default {
  data () {
    return {
      hovered: false
    }
  },

  props: ['project'],

  methods: {
    test (index) {
      this.hovered = index
    }
  }
}
</script>
