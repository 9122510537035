<template>
  <div class="projectSection" v-if="project.images">
    <div class="projectSectionLeft">
      <div class="projectSectionTitle monthBackground">Photos</div>

      <div class="controlsDesktop">
        <div class="imageCount">
          <div class="left" @click="flickity.previous(); slideindex()">&larr;</div>
          <div class="center">{{currentslide}} / {{ pad(project.images.length, 2)}}</div>
          <div class="right" @click="flickity.next(); slideindex()">&rarr;</div>
        </div>
  
        <div class="imageCaption" v-if="currentCaption" v-html="currentCaption"></div>
      </div>
    </div>

    <div class="projectSectionRight">
      <div class="projectBoxNo">
        <div class="leftSlide" @click="flickity.previous()" v-if="!$store.state.isTouch"></div><div class="rightSlide" @click="flickity.next()" v-if="!$store.state.isTouch"></div>

        <div class="slideshowImage" ref="slides">
          <template v-for="image in project.images">
            <div class="slideshowImageInner">
              <img :data-flickity-lazyload="$store.state.cmsFiles + image.src">
            </div>
          </template>
        </div>
      </div>
    
      <div class="controlsMob">
        <div class="imageCount">
          <div class="left" @click="flickity.previous(); slideindex()">&larr;</div>
          <div class="center">{{currentslide}} / {{ pad(project.images.length, 2)}}</div>
          <div class="right" @click="flickity.next(); slideindex()">&rarr;</div>
        </div>
  
        <div class="imageCaption" v-if="currentCaption" v-html="currentCaption"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Flickity from 'flickity'

export default {
  data () {
    return {
      flickity: false,
      currentCaption: this.project.images[0].caption,
      currentslide: '01'
    }
  },

  props: ['project'],

  methods: {
    slideindex () {
      this.currentCaption = this.project.images[this.flickity.selectedIndex].caption
      this.currentslide = this.pad(this.flickity.selectedIndex + 1, 2)
    },

    pad (num, size) {
      var s = num + ''
      while (s.length < size) { s = '0' + s }
      return s
    },

    initSlider () {
      this.flickity = new Flickity(this.$refs.slides, this.$flickOp)

      let that = this
      this.flickity.on('change', function (index) {
        that.currentslide = that.pad(index + 1, 2)
        that.currentCaption = that.project.images[index].caption
      })
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.initSlider()
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/slideshow.scss';

.controlsMob {
  display: none;
}

@media screen and (max-width: $breakPoint1) {
  .controlsMob {
  margin-top: $objectPadding;
    display: block;
  }

  .controlsDesktop {
    display: none;
  }

  .projectSectionRight {
    grid-column: span 6;
  }
}
</style>
