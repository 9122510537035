<template>
  <div id="projects">
    <div class="project" v-for="(project, index) in $store.state.projects" v-if="project.downloads && (project.type === performances || project.type === visual)">
      <project-title :project="project" :ref="'titleHover' + index"></project-title>

      <auteurs :auteurs="project.auteurs" />

      <div class="projectContent">
        <div class="projectLeft">
          <project-thumb :project="project" :index="index" @highlight="highlightTitle"></project-thumb>
        </div>

        <div class="projectBox downloads">
          <template v-for="download in project.downloads">
            <template v-if="download.type === 'image'">
              <p><a :href="$axios.defaults['baseURL'] + '/files/' + download.link + project.url" :download="download.link">
                <font-awesome-icon :icon="'file-' + download.type" />&emsp;<span v-html="download.name" />
              </a></p>
            </template>
            <template v-else>
              <p><a target="_blank" :href="$store.state.cmsFiles + download.link" :download="download.link">
                <font-awesome-icon :icon="'file-' + download.type" />&emsp;<span v-html="download.name" />
              </a></p>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectTitle from '@/components/A/Reusable/Title'
import ProjectThumb from '@/components/A/Reusable/Thumb'
import Auteurs from '@/components/A/Reusable/Auteurs'
import FilterMixins from '@/components/A/Mixins/FilterMixins'
import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFilePdf, faFileVideo, faFileImage, faFileArchive } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faFilePdf, faFileVideo, faFileImage, faFileArchive)
Vue.component('font-awesome-icon', FontAwesomeIcon)

export default {
  components: {
    ProjectTitle,
    ProjectThumb,
    Auteurs
  },

  props: ['performances', 'visual'],

  mixins: [FilterMixins]
}
</script>

<style lang="scss">
.downloads a {
  text-decoration: none;
}

.downloads p {
  margin-bottom: 1rem;
}

.downloads p:last-child {
  margin: 0;
}
</style>
