<template>
  <div class="projectSection" v-if="project.credits">
    <div class="projectSectionLeft">
      <div class="projectSectionTitle monthBackground">Credits</div>
    </div>

    <div class="projectSectionRight">
      <div class="projectBox">
        <project-credit v-for="credit in project.credits" :key="credit.id" :credit="credit"></project-credit>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectCredit from '@/components/A/Reusable/Credit'

export default {
  components: {
    ProjectCredit
  },

  props: ['project']
}
</script>
