<template>
  <div class="projectThumb" @mouseover="highlight(true)" @mouseleave="highlight(false)">
    <router-link :to="'/works/single/' + project.url" class="noUnderline">
      <div class="projectThumbInner">
        <img :src="$store.state.cmsFiles + project.thumbnail.small">
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['project', 'index'],

  methods: {
    highlight (state) {
      let args = {
        index: this.index,
        state: state
      }

      this.$emit('highlight', args)
    }
  }
}
</script>
