<template>
  <div class="auteursTitle">By 
    <template v-for="(item, index) in auteurs">
      <router-link :to="'/information/auteur/' + item.url">{{item.title}}</router-link><template v-if="index !== auteurs.length-1">, </template>
    </template>  
  </div>
</template>

<script>
export default {
  props: ['auteurs']
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

.auteursTitle {
  margin-bottom: $objectPadding;
  padding: $boxPadding;
  border: $border-square;
}
</style>