<template>
  <div class="projectSection" v-if="project.premiere">
    <div class="projectSectionLeft">
      <div class="projectSectionTitle monthBackground">Premiere</div>
    </div>

    <div class="projectSectionRight">
      <div class="projectBox">
        <div class="dateLine">
          <div class="dateYear">{{ project.premiere.year }}</div>
          <div class="dateRight">
            <div class="dateMonth">
              <div class="month" v-html="project.premiere.month + '&nbsp;'"></div>
              <div class="days">{{ project.premiere.start }}</div>
            </div>
  
            <div class="dateTitle">
              <template v-if="project.premiere.website">
                <a :href="project.premiere.website" v-html="project.premiere.venue" target="_blank"></a>
              </template>
              <template v-else>
                <span v-html="project.premiere.venue"></span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['project']
}
</script>

<style lang="scss">
@import '@/assets/scss/dates.scss';
</style>
