<template>
  <div id="information" v-if="$store.state.month">

    <div class="title">
      <div class="titleInner">Information</div>
    </div>
    
    <div id="informationContent">
      <div class="projectSection">
        <div class="projectSectionLeft">
          <div class="projectSectionTitle" :class="$store.state.monthComp">Newsletter</div>
        </div>
    
        <div class="projectSectionRight">
          <div class="projectBox newsletter">

            <div v-html="chimpMessage"></div>

            <form @submit.prevent novalidate="true" id="mailchimp" v-if="!this.chimp">
              <div class="mc-field-group">
              	<label>Email Address<span class="asterisk">*</span></label>
                <input type="email" value="" name="EMAIL" v-model="email" autocomplete="off">
              </div>
  
              <div class="mc-field-group">
              	<label>First Name</label>
              	<input type="text" value="" name="FNAME" v-model="fname" autocomplete="off">
              </div>

              <div class="mc-field-group">
              	<label>Last Name</label>
              	<input type="text" value="" name="LNAME" v-model="lastname" autocomplete="off">
              </div>

              <div class="mc-field-group">
              	<label>Company</label>
              	<input type="text" value="" name="CNAME" v-model="cname" autocomplete="off">
              </div>

              <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" :class="[$store.state.month, { validated: !errors.length }]" @click="clickForm">
            </form>

          </div>
        </div>
      </div>

      <div class="projectSection" v-for="contact in $store.state.information">
        <div class="projectSectionLeft">
          <div class="projectSectionTitle" :class="$store.state.monthComp">{{contact.title}}</div>
        </div>
    
        <div class="projectSectionRight">
          <div class="projectBox" v-html="contact.text"></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ApiMixins from '@/components/A/Mixins/ApiMixins'

export default {
  data () {
    return {
      chimp: false,
      chimpMessage: 'Sign up to our newsletter:',
      contacts: false,
      errorMessage: false,
      errors: [],
      email: '',
      fname: '',
      lastname: '',
      cname: ''
    }
  },

  mixins: [ApiMixins],

  created () {
    this.checkForm(this.email)

    this.$nextTick(() => {
      if (!this.$store.state.information) {
        this.$axios.get('/information').then(result => {
          this.$store.commit('information', result.data.contacts)

          if (!this.$store.state.projects) {
            this.$axios.get('/projects').then(result => {
              this.sortWork(result.data.projects, result.data.texts)
            })
          }
        })
      }
    })
  },

  watch: {
    email: function (val) {
      this.checkForm()
    }
  },

  methods: {
    clickForm: function () {
      this.$axios.post('/subscribe', {email: this.email, fname: this.fname, lname: this.lastname, cname: this.cname}).then(result => {
        if (result.data.signup === 'success') {
          this.chimp = true
          this.chimpMessage = result.data.email + ' has been signed up to the newsletter.'
        }

        if (result.data.signup === 'exists') {
          this.chimp = true
          this.chimpMessage = result.data.email + ' is already signed up to the newsletter.'
        }

        if (result.data.signup === 'fail') {
          this.chimp = false
          this.chimpMessage = 'There was a problem, please try again.'
        }
      })
    },

    checkForm: function () {
      this.errors = []
      if (!this.email) {
        this.errors.push('Email required.')
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.')
      }

      if (!this.errors.length) {
        this.errorMessage = false
        return true
      }
    },

    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

.mc-field-group {
  width: 100%;
  margin-bottom: $objectPadding;
  display: grid;
  grid-template-columns: repeat(8, 1fr);

  label {
    grid-column: span 3;
    position: relative;
  }

  input {
    grid-column: span 5;
    padding: $boxPadding;
    border: $border-square;
    flex: 1;
    border-radius: 0;
  }
}

#mc-embedded-subscribe {
  border: $border-square;
  margin: $objectPadding 0;
  cursor: pointer;
  width: 100%;
  padding: $boxPadding;
  opacity: 0.5;
  pointer-events: none;
  border-radius: 10rem;
}

#mc-embedded-subscribe.validated {
  pointer-events: auto;
  opacity: 1;
}

#mc-embedded-subscribe.validated:hover {
  background-color: black;
  color: white;
}

#informationContent {
  padding: 0 $mainPadding $mainPadding;
}

.info .title {
  margin-bottom: $titleSpacing;
}

form {
  margin-top: $mainPadding;
}

@media screen and (max-width: $breakPoint1) {
  .info .title {
    margin-bottom: 0;
  }

  .mc-field-group {
    label {
      margin-bottom: $objectPadding;
    }

    label,
    input {
      grid-column: span 8;
    }
  }
}
</style>
