export default {
  methods: {
    highlightTitle (args) {
      let numT = Object.keys(this.$refs).length
      let refName = 'titleHover' + args.index
      if (numT > 1) {
        this.$refs[refName][0].test(args.state)
      } else {
        this.$refs[refName].test(args.state)
      }
    }
  }
}
