<template>
  <div id="projects">
    <div class="project" v-for="(project, index) in $store.state.projects" v-if="project.credits && (project.type === performances || project.type === visual)">
      <project-title :project="project" :ref="'titleHover' + index"></project-title>

      <auteurs :auteurs="project.auteurs" />

      <div class="projectContent">
        <div class="projectLeft">
          <project-thumb :project="project" :index="index" @highlight="highlightTitle" /></project-thumb>
        </div>

        <div class="projectBox">
          <project-credit v-for="credit in project.credits" :key="credit.id" :credit="credit"></project-credit>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectTitle from '@/components/A/Reusable/Title'
import ProjectThumb from '@/components/A/Reusable/Thumb'
import Auteurs from '@/components/A/Reusable/Auteurs'
import ProjectCredit from '@/components/A/Reusable/Credit'
import ProjectCreditAuteur from '@/components/A/Reusable/CreditAuteur'
import FilterMixins from '@/components/A/Mixins/FilterMixins'

export default {
  components: {
    ProjectTitle,
    ProjectThumb,
    Auteurs,
    ProjectCredit,
    ProjectCreditAuteur
  },

  props: ['performances', 'visual'],

  mixins: [FilterMixins]
}
</script>